import { createSlice } from '@reduxjs/toolkit';

import { mergeValue } from '~/utils/store';
import themeConfig from '~/configs/theme';

import { AnnotationTypes } from '~/types/app/reader';
import type BookTypes from '~/types/app/book';
import { SearchFacetParamName, SearchFilter, SearchFilterMap } from '~/types/app/search';

import { ContentHitTypes } from '~/types/app/book';
import { SearchResult } from '@allvit-labs/system';

export interface SearchTypes {
    term: string;
    start: number;
    count: number;
    filters: SearchFilterMap;
    backRoute: string;
    loading: boolean;
    book?: BookTypes | null;
    modalOpen: boolean;
    category: 'books' | 'booksContent';
    filterOpen: boolean;
}

interface ModalTypes {
    current: string;
    title?: string;
    width?: string;
    disableOverlayClose?: boolean;
    props?: {
        message?: string | JSX.Element;
        [key: string]: unknown;
    };
}

interface DrawerTypes {
    current: string;
    width?: string;
    title?: string;
    props: {
        [key: string]: unknown;
    };
}

export interface SidebarTypes {
    current: string;
    props: {
        [key: string]: unknown;
    };
}

interface DropdownTypes {
    current: string;
    anchor: any;
}

export interface ReaderTypes {
    currentPageIndex: number; // Still used for bottom bar page navigation in pdf reader
    darkMode: boolean;
    double: boolean; // Used to navigate 2 pages instead of one in pdf reader in double page mode
    fontFamily: string;
    colorProfile: string;
    fontSize: number;
    hideToolbars: boolean;
    fixToolbars: boolean;
    margin: 'narrow' | 'normal' | 'wide';
    pagination: boolean;
    readingAloud: 'hidden' | 'idle' | 'playing' | 'paused';
    showHighlights: boolean;
    status: 'loading' | 'idle' | 'failed';
    totalPageCount: number;
    annotationsOpen: boolean;
    showEditDisabledModal: boolean;
    annotations: AnnotationTypes[];
    headerHeight: number;
    search: {
        term: string;
        loading: boolean;
        searchWholeBook: boolean;
        matchStartInWord: boolean;
        hits: Array<ContentHitTypes | SearchResult>;
    };
    tts: {
        speed: number;
        voice: string;
        autoscrollActive: boolean;
    };
    noteModal: {
        annotationId: string;
        color: string;
        noteBuffer?: string;
        noteText: string;
        selectedText: string;
        status: 'closed' | 'view' | 'edit' | 'saving' | 'deleting';
        pageIndex?: number;
    };
    current: {
        chapter: string;
        progression: number;
        headingId?: string;
        historyCurrentIndex?: number;
        historyLength?: number;
    };
    drawer: {
        mode: '__closed__' | 'original' | 'summary' | 'audio' | 'chat';
    };
}

export interface InitialStateTypes {
    search: SearchTypes;
    modal: ModalTypes;
    drawer: DrawerTypes;
    sidebar: SidebarTypes;
    dropdown: DropdownTypes;
    reader: ReaderTypes;
    leftVerticalNavbar: {
        open: boolean;
        width: number;
        books: BookTypes[];
    };
    mobileMenu: {
        open: boolean;
    };
    bookContentPanel: {
        width: string;
    };
}

export const initialState: InitialStateTypes = {
    search: {
        term: '',
        start: 0,
        count: 1,
        filters: [] as SearchFilterMap,
        backRoute: '',
        loading: false,
        book: null,
        modalOpen: false,
        category: 'books',
        filterOpen: false,
    },
    modal: {
        current: '__none__',
        title: '',
        width: 'medium',
        disableOverlayClose: false,
        props: {},
    },
    drawer: {
        current: '__none__',
        props: {},
    },
    sidebar: {
        current: '__none__',
        props: {},
    },
    dropdown: {
        current: '__none__',
        anchor: null,
    },
    reader: {
        currentPageIndex: 0,
        darkMode: false,
        fontFamily: 'default',
        double: false,
        fontSize: 100,
        colorProfile: 'default',
        hideToolbars: false,
        fixToolbars: true,
        margin: 'normal',
        pagination: false,
        readingAloud: 'hidden',
        showHighlights: true,
        status: 'loading',
        totalPageCount: 0,
        showEditDisabledModal: false,
        search: {
            term: '',
            loading: false,
            hits: [],
            matchStartInWord: false,
            searchWholeBook: false,
        },
        annotationsOpen: false,
        annotations: [],
        tts: {
            speed: 1,
            voice: '',
            autoscrollActive: true,
        },
        noteModal: {
            annotationId: '',
            color: '',
            noteBuffer: '',
            noteText: '',
            selectedText: '',
            status: 'closed',
            pageIndex: 0,
        },
        current: {
            chapter: '',
            progression: 0,
        },
        drawer: {
            mode: '__closed__',
        },
        headerHeight: 80,
    },
    leftVerticalNavbar: {
        open: true,
        width: themeConfig.globalMenuWidthOpen,
        books: [],
    },
    mobileMenu: {
        open: false,
    },
    bookContentPanel: {
        width: '420px',
    },
};

export default createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setValue: {
            reducer: (state, action) => mergeValue(state, action.payload, action.meta),
            prepare: (value, path) => ({ payload: value, meta: path, error: null }),
        },
        setSearch: (state, action) => {
            state.search = { ...state.search, ...action.payload };
        },
        openMobileMenu: (state) => {
            state.mobileMenu.open = true;
        },
        closeMobileMenu: (state) => {
            state.mobileMenu.open = false;
        },
        openGlobalMenu: (state) => {
            state.leftVerticalNavbar.width = themeConfig.globalMenuWidthOpen;
            state.leftVerticalNavbar.open = true;
        },
        closeGlobalMenu: (state) => {
            state.leftVerticalNavbar.width = themeConfig.globalMenuWidthClosed;
            state.leftVerticalNavbar.open = false;
        },
        setModal: (state, action) => {
            state.modal = {
                ...initialState.modal,
                ...action.payload,
            };
        },
        closeModal: (state) => {
            state.modal = initialState.modal;
        },
        setDropdown: (state, action) => {
            state.dropdown = {
                ...initialState.dropdown,
                ...action.payload,
            };
        },
        closeDropdown: (state) => {
            state.dropdown = initialState.dropdown;
        },
        setSidebar: (state, action) => {
            state.sidebar = {
                ...initialState.sidebar,
                ...action.payload,
            };
        },
        closeSidebar: (state) => {
            state.sidebar = initialState.sidebar;
        },
        clearFilter: (state) => {
            state.search = { ...state.search, filters: initialState.search.filters };
        },
        removeFromFilter: (state, action) => {
            state.search.filters = state.search.filters.filter(
                (i: SearchFilter) => i.facet !== action.payload.facet || i.value !== action.payload.value,
            );
        },
        addToFilter: (state, action) => {
            const facet: SearchFacetParamName = action.payload.facet;
            const value: string = action.payload.value;
            let filters: SearchFilterMap = [];

            // Makes sure that the availability filters are grouped at the end
            if (
                facet === SearchFacetParamName.FreeBook ||
                facet === SearchFacetParamName.Rent ||
                facet === SearchFacetParamName.Buy ||
                facet === SearchFacetParamName.IncludedInSubscription
            ) {
                filters = [...state.search.filters, { facet, value }];
            } else {
                filters = [{ facet, value }, ...state.search.filters];
            }

            state.search.filters = filters;
        },
        setDrawer: (state, action) => {
            state.drawer = {
                ...initialState.drawer,
                ...action.payload,
            };
        },
        closeDrawer: (state) => {
            state.drawer = initialState.drawer;
        },
        setNoteModal: (state, action) => {
            state.reader.noteModal = {
                ...state.reader.noteModal,
                ...action.payload,
            };
        },
        resetNoteModal: (state) => {
            state.reader.noteModal = initialState.reader.noteModal;
        },
    },
});
