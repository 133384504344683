// ** MUI Imports
import { PaletteMode } from '@mui/material';

// ** Types
export type ContentWidth = 'full' | 'boxed';

type ThemeConfig = {
    mode: PaletteMode;
    templateName: string;
    routingLoader: boolean;
    disableRipple: boolean;
    contentWidth: ContentWidth;
    responsiveFontSizes: boolean;
    drawerWidthOpen: number;
    drawerWidthClosed: number;
    globalMenuWidthOpen: number;
    globalMenuWidthClosed: number;
    topRowHeight: number;
    modalWidths: {
        xtight: number;
        tight: number;
        medium: number;
        wide: number;
    };
    annotationColors: string[];
};

const themeConfig: ThemeConfig = {
    templateName: 'Allvit',
    mode: 'light' /* light | dark */,
    contentWidth: 'boxed' /* full | boxed */,
    routingLoader: true,
    responsiveFontSizes: true,
    disableRipple: true,
    drawerWidthOpen: 210,
    drawerWidthClosed: 89,
    globalMenuWidthOpen: 260,
    globalMenuWidthClosed: 80,
    topRowHeight: 80,
    modalWidths: {
        xtight: 200,
        tight: 500,
        medium: 700,
        wide: 1200,
    },
    annotationColors: [
        '#FCE300', // Yellow
        '#48E200', // Greeen
        '#00BAE5', // Cyan
        '#157CF9', // Blue
        '#6A39B7', // Purple
        '#EA426A', // Red
        '#FF8500', // Yellow
    ],
};

export default themeConfig;
